<template>
  <vams-collapse
    id="billing-and-payment-collapse"
    :ref="'billing-and-payment'"
    title="Billing and Payment"
    :visible="visible"
  >
    <b-row no-gutters>
      <b-col class="customer-info-column">
        <div style="margin-bottom: 10px">BILL TO:</div>
        <b-row>
          <b-col>
            <div>
              <div>
                <router-link v-if="!$isCustomer" :to="'/customers/' + billToCustomer.customerId">
                  {{ billToCustomer.customerId }} {{ billToCustomer.customerName }}
                </router-link>
                <div v-else>{{ billToCustomer.customerId }} {{ billToCustomer.customerName }}</div>
              </div>
            </div>
            <div style="padding-top: 10px">
              {{ billToCustomer.address1 }}
            </div>
            <div>{{ billToCustomer.address2 }}</div>
            <div>{{ billToCustomer.city }}, {{ billToCustomer.state }} {{ billToCustomer.zip }}</div>
            <div>
              {{ billToCustomer.county }}
            </div>
            <div class="customer-phone">
              {{ billToCustomer.phone | phone }}
            </div>
            <b-badge v-if="customer.poRequired" class="po-required-badge mb-2" variant="danger">
              <span>PO Required</span>
            </b-badge>
          </b-col>
          <b-col>
            <b-form-group v-if="!readonly && !$isCustomer" style="width: 150px">
              <label class="col-head">Available Credit</label>
              <div :class="isCreditRed" class="available-credit">
                {{ billToCustomer.availableCredit | currency }}
              </div>
            </b-form-group>
            <b-form-group>
              <label class="col-head">Terms</label>
              <div>{{ billToCustomer.termId }}</div>
            </b-form-group>
            <b-form-group v-if="!$isCustomer" label="PO #" label-class="col-head" label-for="purchase-order">
              <template #label>
                PO #
                <sup>
                  <font-awesome-icon
                    id="purchase_order_tooltip"
                    icon="info-circle"
                    size="lg"
                    class="mx-1 text-primary align-self-center"
                    display="inline-block"
                  />
                  <b-tooltip target="purchase_order_tooltip" triggers="hover">
                    Updated by {{ poUpdatedByType }}
                    <div>{{ poUpdatedByName }}</div>
                    <div>{{ datePoUpdated | dateTime }}</div>
                  </b-tooltip>
                </sup>
              </template>
              <div v-if="readonly" id="purchase-order">
                <div>
                  {{ purchaseOrder | nullValueToNA }}
                </div>
              </div>
              <b-input-group v-else>
                <b-form-input id="purchase-order" v-model="purchaseOrder" maxlength="30" />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="!readonly && isCsrOrCsm" style="margin-top: 20px">
          <b-col>
            <b-button v-if="!changing" variant="secondary" @click="startChange">Change Bill-To</b-button>
            <div v-else>
              <customer-search-input v-model="newBillToCustomer" label="New Bill-To Customer" @input="selectCustomer" />
              <div>
                <b-button variant="primary" class="mr-3" @click="confirmSave">Save</b-button>
                <b-button variant="secondary" @click="cancel">Cancel</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-if="canViewPaymentRequest && !$isCustomer" style="border-left: 1px solid lightgray; padding-left: 15px">
        <e-pay-component :order-id="serviceOrderId" :order="serviceOrder" @updateServiceOrder="updateServiceOrder" />
      </b-col>
      <b-col v-else></b-col>
    </b-row>
    <unsaved-changes-modal
      ref="ConfirmSaveModal"
      title="Save Changes"
      :message="confirmMessage"
      continue-btn-text="Change Bill-To"
      cancel-btn-text="Discard"
    />
  </vams-collapse>
</template>

<script>
// components
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';
import CollapseComponent from '@/shared/components/ui/CollapseComponent';
import CustomerSearchInput from '@/shared/components/customer/CustomerSearchInput';
import EPayComponent from '@/shared/components/ui/EPayComponent';
// vuex
import { mapActions, mapGetters } from 'vuex';
import { UserGetters } from '@/shared/store/user/types';
import { CustomerActions } from '@/shared/store/customer/types';
import { ServiceOrderActions, ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { FORM_STAGES } from '@/shared/store/service-order/state';
// helpers
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';

export default {
  name: 'BillingAndPaymentComponent',
  components: {
    'unsaved-changes-modal': UnsavedChangesModal,
    'vams-collapse': CollapseComponent,
    'customer-search-input': CustomerSearchInput,
    'e-pay-component': EPayComponent
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    visible: Boolean()
  },
  data: function () {
    return {
      FORM_STAGES: FORM_STAGES,
      changing: false
    };
  },
  computed: {
    ...mapGetters({
      authorizeRole: UserGetters.AUTHORIZE_ROLE,
      customer: ServiceOrderGetters.GET_CUSTOMER,
      stage: ServiceOrderGetters.GET_FORM_STAGE,
      serviceOrderId: ServiceOrderGetters.GET_SERVICE_ORDER_ID,
      estimorderDisplayText: ServiceOrderGetters.GET_ESTIMORDER_DISPLAY_TEXT,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      allowEPay: ServiceOrderGetters.GET_ALLOW_E_PAY
    }),
    canViewPaymentRequest() {
      return (
        this.isCsrOrCsm &&
        (this.serviceOrder.allowEPay || this.serviceOrder.allowEPayInPerson) &&
        !this.serviceOrder.isEstimate
      );
    },
    isCsrOrCsm() {
      return this.authorizeRole(['CSR', 'CSM', 'RegionalCSM']);
    },
    unitId() {
      return this.$route.params.unitId;
    },
    isCreditRed() {
      return this.billToCustomer.availableCredit < 0 ? 'credit-red' : '';
    },
    purchaseOrder: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_PURCHASE_ORDER];
      },
      set(value) {
        this.$store.commit(ServiceOrderMutations.SET_PROP, { key: 'purchaseOrder', value });
      }
    },
    billToCustomer: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_BILL_TO_CUSTOMER];
      },
      set(value) {
        this.$store.commit(ServiceOrderMutations.SET_PROP, { key: 'billToCustomer', value });
      }
    },
    newBillToCustomer: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_NEW_BILL_TO_CUSTOMER];
      },
      set(value) {
        this.$store.commit(ServiceOrderMutations.SET_PROP, { key: 'newBillToCustomer', value });
      }
    },
    confirmMessage() {
      if (this.newBillToCustomer) {
        return `Are you sure you want to change the Bill-To-Customer to ${this.newBillToCustomer.customerId} - ${this.newBillToCustomer.customerName}?`;
      } else {
        return null;
      }
    },
    poUpdatedByType() {
      return this.serviceOrder.poUpdatedByType;
    },
    poUpdatedByName() {
      return this.serviceOrder.poUpdatedByName;
    },
    datePoUpdated() {
      return this.serviceOrder.datePoUpdated;
    }
  },
  methods: {
    ...mapActions([
      CustomerActions.FETCH_CUSTOMER,
      ServiceOrderActions.UPDATE_BILL_TO_CUSTOMER,
      ServiceOrderActions.FETCH_CUSTOMER_SPECIFIC_REPAIR_REASONS,
      ServiceOrderActions.FETCH_BILL_TO_CUSTOMER_ALERTS
    ]),
    async switchStage(newStage) {
      var switchStageSuccess = await this.$store.dispatch(ServiceOrderActions.SWITCH_STAGE, {
        newStage,
        that: this
      });

      return switchStageSuccess;
    },
    startChange() {
      this.changing = true;
    },
    async selectCustomer(customer) {
      this.newBillToCustomer = await this[CustomerActions.FETCH_CUSTOMER](customer.customerId);
    },
    confirmSave() {
      this.$refs.ConfirmSaveModal.show(this, this.save);
    },
    async save(cancel = true) {
      if (cancel) {
        this.cancel();
        return;
      }

      this.billToCustomer = this.newBillToCustomer;

      if (this.stage == FORM_STAGES.EDIT_SO) {
        try {
          this.switchStage(FORM_STAGES.SAVING_SO);
          const response = await this[ServiceOrderActions.UPDATE_BILL_TO_CUSTOMER]({
            unitId: this.unitId,
            serviceOrderId: this.serviceOrderId
          });

          this[ServiceOrderActions.FETCH_CUSTOMER_SPECIFIC_REPAIR_REASONS](this.billToCustomer.customerId);
          this[ServiceOrderActions.FETCH_BILL_TO_CUSTOMER_ALERTS](this.billToCustomer.customerId);

          SuccessService.createSuccessToast(
            this.$root,
            `${this.estimorderDisplayText} #${response.serviceOrderId} saved successfully.`
          );
        } catch {
          const errorMessage = `Error saving ${this.estimorderDisplayText}.`;
          ErrorService.createErrorToast(this, errorMessage);
          throw Error(errorMessage);
        } finally {
          this.switchStage(FORM_STAGES.EDIT_SO);
        }
      }
      this.cancel();
    },
    cancel() {
      this.changing = false;
      this.newBillToCustomer = null;
    },
    async updateServiceOrder() {
      this.$emit('updateServiceOrder');
    }
  }
};
</script>
